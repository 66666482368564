<template>
    <li class="accordion__item">
        <div
            class="accordion__trigger"
            :class="{'accordion__trigger_active': visible}"
            @click="open">
            <slot name="accordion-trigger"></slot>
            <span v-show="!this.visible" class="glyphicon glyphicon-menu-down"></span>
            <span v-show="this.visible" class="glyphicon glyphicon-menu-up"></span>
        </div>

        <transition
            name="accordion"
            @enter="start"
            @after-enter="end"
            @before-leave="start"
            @after-leave="end">

            <div class="accordion__content"
                 v-show="visible">
                <ul>
                    <!-- This slot will handle all the content that is passed to the accordion -->
                    <slot name="accordion-content"></slot>
                </ul>
            </div>
        </transition>
        <div v-show="visible"
             class="accordion__trigger trigger-close"
             :class="{'accordion__trigger_active': visible} "
             @click="close">
            <slot name="accordion-close"></slot>
        </div>
    </li>
</template>


<script>
export default {
    props: {},
    inject: ["Accordion"],
    data() {
        return {
            index: null
        };
    },
    computed: {
        visible() {
            return this.index == this.Accordion.active;
        }
    },
    methods: {
        open() {
            if (this.visible) {
                this.Accordion.active = null;
            } else {
                this.Accordion.active = this.index;
            }
        },
        close(){
            this.Accordion.active = null;
            this.Accordion.active = this.index + 1;
        },
        start(el) {
            el.style.height = el.scrollHeight + "px";
        },
        end(el) {
            el.style.height = "";
        }
    },
    created() {
        this.index = this.Accordion.count++;
    }
};
</script>

<style scoped>
.accordion__item {
    cursor: pointer;
    padding: 5px 20px 5px 0px;
    border: 1px solid #ebebeb;
    border-radius: 4px;
    position: relative;
}

.accordion__trigger {
    display: flex;
    justify-content: space-between;
}
.accordion-enter-active,
.accordion-leave-active {
    will-change: height, opacity;
    transition: height 0.3s ease, opacity 0.3s ease;
    overflow: hidden;
}

.accordion-enter,
.accordion-leave-to {
    height: 0 !important;
    opacity: 0;
}
ul{
    padding: 0px;
    margin: 0px;
}
.trigger-close{
    text-align: right;
    display: block;
}
.glyphicon{
    padding: 5px;
    font-size: x-large;
}
@media (max-width: 850px) {
    .glyphicon{
        font-size: small!important;
    }
}

</style>
